"use client";

import { useRouter } from "next/navigation";
import { SignInButton, useAuth } from "@clerk/nextjs";
import React, { FC, useState, useTransition } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Chat as ChatIcon } from "@mui/icons-material";
import { TradeItem } from "modules/tradeLists/model/tradeList";
import { getOrCreateChat } from "modules/server/actions/chat";

interface ContactUserButtonProps {
  item: TradeItem;
  text?: string;
  small?: boolean;
}

const RecentTradesContactButton: FC<ContactUserButtonProps> = ({
  item,
  text = "Message",
  small,
}) => {
  const router = useRouter();
  const { userId, isSignedIn } = useAuth();
  const [isPending, startTransition] = useTransition();
  const [error, setError] = useState<string | null>(null);

  const handleCreateChat = async () => {
    if (!isSignedIn) return;

    setError(null);
    startTransition(async () => {
      try {
        const chat = await getOrCreateChat([userId!, item.user.id]);
        router.push(`/inbox/${chat.id}`);
      } catch (err) {
        setError("Failed to create chat. Please try again.");
      }
    });
  };

  return (
    <>
      {isSignedIn ? (
        small ? (
          <Tooltip title={"Message"} placement={"top"} arrow>
            <IconButton
              onClick={handleCreateChat}
              color="primary"
              aria-label={isPending ? "Loading..." : "Message"}
              size={"small"}
              disabled={isPending}
            >
              {isPending ? <CircularProgress size={24} /> : <ChatIcon />}
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            onClick={handleCreateChat}
            variant="contained"
            fullWidth
            aria-label={isPending ? "Loading..." : "Message"}
            size="small"
            disabled={isPending}
            startIcon={
              isPending ? (
                <CircularProgress size={16} />
              ) : (
                <ChatIcon fontSize="small" />
              )
            }
          >
            {text}
          </Button>
        )
      ) : (
        <SignInButton mode="modal">
          {small ? (
            <Tooltip title={"Message"} placement={"top"} arrow>
              <IconButton
                color="primary"
                size={"small"}
                aria-label={isPending ? "Loading..." : "Message"}
                disabled={isPending}
              >
                {isPending ? <CircularProgress size={24} /> : <ChatIcon />}
              </IconButton>
            </Tooltip>
          ) : (
            <Button
              variant="contained"
              fullWidth
              aria-label={isPending ? "Loading..." : "Message"}
              size="small"
              disabled={isPending}
              startIcon={
                isPending ? (
                  <CircularProgress size={16} />
                ) : (
                  <ChatIcon fontSize="small" />
                )
              }
            >
              {text}
            </Button>
          )}
        </SignInButton>
      )}
      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 0.5 }}>
          {error}
        </Typography>
      )}
    </>
  );
};

export default RecentTradesContactButton;
