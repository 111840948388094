import React from "react";
import { SxProps, TableCell, TableHead, TableRow } from "@mui/material";

const headerStyle = {
  pt: 1,
  pb: 0.5,
  px: 0.1,
  fontSize: "0.9rem",
  bgcolor: "background.paper",
} as SxProps;

export const RecentTradesTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ ...headerStyle, pl: 1 }}>Qty.</TableCell>

        <TableCell sx={headerStyle}>Name</TableCell>
        <TableCell sx={{ ...headerStyle, textAlign: "center" }} width={"5%"}>
          Set
        </TableCell>
        <TableCell sx={{ ...headerStyle, width: 45 }}>Price</TableCell>
        <TableCell sx={headerStyle}>Finish</TableCell>
        <TableCell sx={{ ...headerStyle, textAlign: "center" }}>User</TableCell>
        <TableCell
          sx={{
            ...headerStyle,
            textAlign: "center",
            display: { xs: "none", md: "table-cell" },
          }}
        >
          Added
        </TableCell>
        <TableCell sx={{ ...headerStyle, textAlign: "center" }}>Cond</TableCell>
        <TableCell sx={{ ...headerStyle, textAlign: "center" }}>Lang</TableCell>
        <TableCell sx={{ ...headerStyle, textAlign: "center" }}>
          Contact
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default RecentTradesTableHeader;
