"use client";
import React from "react";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { CardPreviewLoading } from "modules/common/components/CardPreviewLoading";
import { FinishEnum } from "modules/cards/model/card";
import { TradeItem } from "modules/tradeLists/model/tradeList";
import { formatDistanceToNow } from "date-fns";
import {
  cardConditionStrings,
  cardConditionStringsFull,
  languageCountryCodes,
} from "modules/common/model/models";
import { useSets } from "modules/common/hooks/sets";
import Image from "next/image";
import useTheme from "@mui/material/styles/useTheme";
import { useAuth } from "@clerk/nextjs";
import RecentTradesContactButton from "modules/tradeLists/components/RecentTrades/RecentTradesContactButton";

interface Props {
  item: TradeItem;
}

export const RecentTradePreviewCard = ({ item }: Props) => {
  const sets = useSets();
  const theme = useTheme();
  const { userId } = useAuth();
  return (
    <Box sx={{ maxWidth: "240px", position: "relative" }}>
      <Box>
        <CardPreviewLoading
          card={item.data}
          foil={item.finishes.includes(FinishEnum.Foil)}
        />
        <Box
          sx={{
            bgcolor: "background.paper",
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            mt: -1,
          }}
        >
          <Box display="flex" justifyContent="space-between" p={1} pt={2}>
            <Box
              textAlign="right"
              display={"flex"}
              alignItems={"center"}
              justifyContent={`space-between`}
              gap={1}
              width={"100%"}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <Link
                  href={`/users/${item.user.username}`}
                  underline="none"
                  display={"inline-flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <Tooltip
                    title={`User: ${item.user.username}`}
                    placement={"top"}
                    arrow
                  >
                    <Avatar
                      sx={{
                        bgcolor: "#fff",
                        width: 24,
                        height: 24,
                        border: `1px solid ${theme.palette.primary.main}`,
                      }}
                      alt={item.user.username}
                      src={item.user.preferredAvatar}
                    />
                  </Tooltip>
                  <Typography
                    color={"text.secondary"}
                    sx={{
                      mt: 0,
                      p: 0,
                    }}
                  >
                    {item.user.username}
                  </Typography>
                </Link>
              </Box>

              {item.anyPrinting && (
                <Tooltip
                  title={
                    sets.query?.data &&
                    (item.anyPrinting
                      ? "Any set / printing"
                      : sets.query?.data[item.data.set.code].name)
                  }
                  placement={"top"}
                  arrow
                >
                  <Typography
                    component={"span"}
                    color={"secondary"}
                    sx={{
                      fontSize: item.anyPrinting ? "0.9em" : "1.2em",
                    }}
                  >
                    {item.anyPrinting ? (
                      "Any set"
                    ) : (
                      <i
                        className={`ss ss-${item.data.set.keyruneCode.toLowerCase()} ss-grad`}
                      />
                    )}
                  </Typography>
                </Tooltip>
              )}

              <Box display={"flex"} gap={0.5}>
                <Typography>{item.quantity} </Typography>
                <Typography color="text.secondary">x </Typography>

                {item.data.price && (
                  <Typography>
                    €{" "}
                    {item.finishes.includes(FinishEnum.Foil)
                      ? item.data.price.value.foil.toFixed(2)
                      : item.data.price.value.default.toFixed(2)}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={1}
          >
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <Tooltip
                title={cardConditionStringsFull[item.condition]}
                placement="top"
                arrow
              >
                <Chip
                  label={cardConditionStrings[item.condition]}
                  size="small"
                  sx={{
                    backgroundColor:
                      theme.palette.cardCondition[item.condition],
                    color: "#ffffff",
                  }}
                />
              </Tooltip>
              <Tooltip title={item.language} placement={"top"} arrow>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    position={"relative"}
                    width={24}
                    height={24}
                    overflow={"hidden"}
                  >
                    <Image
                      loading="lazy"
                      fill
                      style={{ objectFit: "contain" }}
                      src={`https://flagcdn.com/${languageCountryCodes[
                        item.language
                      ].toLowerCase()}.svg`}
                      alt={item.language}
                    />
                  </Box>
                </Box>
              </Tooltip>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2" color="text.secondary">
                {formatDistanceToNow(new Date(item.updatedAt), {
                  addSuffix: true,
                })}
              </Typography>
            </Box>
          </Box>
          {userId !== item.user.id && (
            <>
              <Divider />
              <Box display="flex" justifyContent="space-between" p={1}>
                <RecentTradesContactButton item={item} />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RecentTradePreviewCard;
