import { useUserDecks } from "modules/decks/hooks/useUserDecks";
import React from "react";
import { useSetRecoilState } from "recoil";
import { confirmDeleteModal } from "modules/common/components/ConfirmDeleteModal";
import {
  Box,
  CircularProgress,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface DeckItemActionProps {
  id: string;
  name: string;
}

export const DeckListItemAction = ({ id, name }: DeckItemActionProps) => {
  const { deleteDeck } = useUserDecks();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const setConfirmDeleteModal = useSetRecoilState(confirmDeleteModal);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDelete = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);

    setConfirmDeleteModal({
      open: true,
      name: name,
      loadingButton: true,
      mutation: {
        op: deleteDeck,
        req: id,
      },
    });
  };

  return (
    <Box position={"absolute"} top={5} right={5}>
      <IconButton
        id={`deck-action-button-${id}`}
        color={"secondary"}
        aria-label="settings"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`deck-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "60px",
            }}
          >
            {deleteDeck.isPending ? (
              <CircularProgress size={20} />
            ) : (
              <ListItemText
                sx={{
                  textAlign: "center",
                }}
              >
                Delete
              </ListItemText>
            )}
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};
