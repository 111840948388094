import { useFetchWrapper } from "modules/common/hooks/useFetchWrapper";
import {
  DeckCopyRequest,
  DeckCreateRequest,
  DeckDeleteResponse,
  DeckDetails,
  DecksPagedRequest,
  DecksPagedResponse,
} from "modules/decks/model/deck";
import { useSnackbar } from "notistack";
import { API_ROUTES } from "utils/api-route.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERIES } from "modules/common/query";
import { ServerError } from "modules/common/model/models";
import { useRouter } from "next-nprogress-bar";

export const useUserDecks = () => {
  const fetch = useFetchWrapper();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const router = useRouter();

  return {
    create,
    deleteDeck: useMutation({
      mutationFn: _delete,
      onSuccess: (data) => {
        if (data.listId?.length) {
          queryClient.refetchQueries({
            queryKey: [QUERIES.TRADE_LISTS_PAGED],
          });
          queryClient.refetchQueries({
            queryKey: [QUERIES.TRADE_LISTS_DETAILS, data.listId],
          });
        }
        queryClient.invalidateQueries({
          queryKey: [QUERIES.DECK_USER],
        });
      },
      onError: (error: ServerError) => {
        enqueueSnackbar(`Failed deleting deck: ${error.text || error.message}`);
      },
    }),
    copyDeck: useMutation({
      mutationFn: copy,
      onSuccess: (data) => {
        enqueueSnackbar("Deck copied", { variant: "success" });
        queryClient.setQueryData([QUERIES.DECK_DETAILS, data.id], data);
        router.push(`/decks/${data.id}`);
        queryClient.invalidateQueries({
          queryKey: [QUERIES.DECK_USER],
        });
      },
      onError: (error: ServerError) => {
        enqueueSnackbar(`Failed copying deck: ${error.text || error.message}`);
      },
    }),

    paged: paged,
  };

  function _delete(id: string) {
    return fetch
      .delete(API_ROUTES.DECKS.DELETE(id), null)
      .then((res: DeckDeleteResponse) => {
        enqueueSnackbar("Deck deleted", { variant: "success" });
        return res;
      });
  }

  function create(req: DeckCreateRequest) {
    return fetch.post(API_ROUTES.DECKS.CREATE, req).then((res: DeckDetails) => {
      enqueueSnackbar("Deck created", { variant: "success" });
      return res;
    });
  }

  function paged(req: DecksPagedRequest) {
    const query = `?user_id=${req.userId}&query=${req.query}&page=${req.page}&per_page=${req.perPage}&sort=${req.sort}&order=${req.order}&format=${req.formatFilter}`;
    return fetch
      .get(API_ROUTES.DECKS.GET_USER + query, null)
      .then((res: DecksPagedResponse) => res);
  }

  function copy(req: DeckCopyRequest) {
    return fetch
      .post(API_ROUTES.DECKS.COPY(req.deckId), null)
      .then((res: DeckDetails) => res);
  }
};
